import React, { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Box, Typography } from "@mui/material";

const SectionTwo = () => {
  const tl = useRef();
  const el = useRef();
  const q = gsap.utils.selector(el);

  
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    
    const posX = (window.innerWidth + 32) / -1;
    const posY = window.innerWidth - 32; // 32 = width of right target (cross hair)

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".section2",
        start: "top center-=40%",
      }
    })
    .fromTo(q(".target-left"), {x:posX}, {x:0})
    .fromTo(q(".target-right"), {x:`+=${posY}`}, {x:0}, "-=.5")
      
  }, [q]);

  const onEnter = (e) => {
    gsap.fromTo(e, {opacity: 0, y:20}, {opacity:1, y:0});
  };

  const onLeave = (e) => {
    gsap.fromTo(e, {opacity: 1, y:0}, {opacity:0, y:20});
  };

  return (
    <Box
      ref={el}
      className="section2"
      style={styles.root}
      >
      <Box 
        width={{ sm:300, md:850 }}
        style={styles.select}
      >
        <Box mt={-1.5}>
          <img 
            className="target-left"
            alt="target-left"
            src="/assets/images/target-left.svg" 
            width={32}
          />
        </Box>
        <Box
          // width={{sm:100, md:420}}
          display="flex"
          justifyContent={"space-between"}
          // sx={{border: "1px red solid"}}
        >
          <Box
            onMouseEnter={() => onEnter(q(".prev-title"))}
            onMouseLeave={() => onLeave(q(".prev-title"))}
            sx={{cursor: "pointer"}}
          >
            <Typography 
              fontSize={56}
              fontFamily={"Avenir-Black"}
            >
              PREVIOUS
            </Typography>
            <Typography 
              className="prev-title"
              fontSize={16}
              sx={{opacity:0}}
            >
              VIEW PREVIOUS WORK
            </Typography>
          </Box>

          <Box
            mt={1.5}
            ml={2}
            mr={2}
            height={60}
            sx={{borderLeft: "5px solid black"}} 
          />
          
          <Box
            onMouseEnter={() => onEnter(q(".recent-title"))}
            onMouseLeave={() => onLeave(q(".recent-title"))}
            sx={{cursor: "pointer"}}
          >
            <Typography 
              fontSize={56}
              fontFamily={"Avenir-Black"}
              color="black"
            >
              RECENT
            </Typography>
            <Typography 
              className="recent-title"
              fontSize={16}
              color={"black"}
              sx={{opacity:0}}
            >
              VIEW RECENT WORK
            </Typography>
          </Box>
        </Box>
        <Box mt={-1.5}>
          <img 
            className="target-right"
            alt="target-right"
            src="/assets/images/target-right.svg" 
            width={32} 
          />
        </Box>          
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#007AB7", 
    color: "white",
    height: window.innerHeight,
    // border: "1px red solid",
  },
  select: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    // top: -270,
    // left: 15,
    // height: 0,
    // border: "1px white solid"
  }
};



export default SectionTwo
