import React, { useLayoutEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';


const SectionThree = () => {
  const tl = useRef();
  const el = useRef();
  
  const q = gsap.utils.selector(el);

  const brands = {
    a: [
      "tiger-brands.svg",
      "all-gold.svg",
      "koo.svg",
      "absa.svg",
      "ab-in-bev.svg",
      "kjsa.svg",
      "sa-tourism.svg",
    ],
    b: [
      "tastic.svg",
      "kauai.svg",
      "coca-cola.svg",
      "bmw.svg",
      "unilever.svg",
      "black-cat.svg",
    ],
    c: [
      "mrs-hs-balls.svg",
      "ingrams.svg",
      "easy-waves.svg",
    ]
  };  

  // useLayoutEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   tl.current = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".head",
  //       start: "top center"
  //     }
  //   })
  //   .fromTo(q(".head"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
  //   .fromTo(q(".txt1"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
  //   .fromTo(q(".txt2"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
  //   .fromTo(q(".txt3"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
  //   .fromTo(q(".logo"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.5, ease:"bounce"})
  //   .fromTo(q(".txt4"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25}, "<-=0.5");

  //   const hideTopAnimation = gsap.to(q(".top"), {
  //     scrollTrigger: {
  //       trigger: ".top",
  //       start: "bottom 70%",
  //       toggleActions: "play pause reverse reset"
  //     },
  //     y: 50,
  //     opacity: 0
  //   });

  //   const expandAnimation = gsap.to(q(".logo"), {
  //     scrollTrigger: {
  //       trigger: ".bottom",
  //       start: "top 90%",
  //       toggleActions: "play pause reverse reverse",
  //       scrub: true
  //     },
  //     width:120,
  //     y: -20
  //   });

  //   return () => {
  //     // tl.kill();
  //     hideTopAnimation.kill();
  //     expandAnimation.kill();
  //   };
  // }, [q]);

  return (
    <Box
      ref={el}
      className="section"
      style={styles.root}
    >
      <Box 
        className="top"
        backgroundColor={"#AE8CD2"}
      >
        <Typography
          className="head"
          fontSize={{xs: 18, md:30}}
          mb={3}
        >
          COLLABORATIONS
        </Typography>

        <Box
          mb={6}
          padding={{sm: "0 2px", md:"0 180px", xl: "0 500px"}}
        >
          <Typography
            className="txt1"
            fontSize={{xs:34, md: 48}}
            fontWeight={"bold"}
          >
            Brands we've worked with
          </Typography>
        </Box>
      </Box>

      <Box 
        className="brands"
      >
        <Box 
          className="a"
          padding={"80px 0"}
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          backgroundColor={"#AE8CD2"}
        >
          {brands.a.map((brand, i) => (
            <Box width={{xs:80, md:100}}>
              <img key={i} alt={brand} src={`/assets/images/brands/${brand}`} width={"100%"} />
            </Box>
          ))}
        </Box>
      
        <Box 
          className="aqua"
          backgroundColor="#80E1FF"
          pb={20}
          style={styles.aqua}
        >
          <Box 
            className="b"
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            padding={{xs:4, md:12}}
          >
            {brands.b.map((brand, i) => (
              <Box width={{xs:70, md:100}}>
                <img key={i} alt={brand} src={`/assets/images/brands/${brand}`} width={"100%"} />
              </Box>
            ))}
          </Box>
          <Box 
            className="c"
            display={"flex"}
            justifyContent={"space-evenly"}
            flexWrap={"wrap"}
            alignItems={"center"}
            padding={{xs:"0 2px", md:"0 240px"}}
          >
            {/* {brands.c.map((brand, i) => (
              <img key={i} alt={brand} src={`/assets/images/brands/${brand}`} width={110} />
            ))} */}
            <img alt={brands.c[0]} src={`/assets/images/brands/${brands.c[0]}`} width={150} />
            <img alt={brands.c[1]} src={`/assets/images/brands/${brands.c[1]}`} width={150} />
            <img alt={brands.c[2]} src={`/assets/images/brands/${brands.c[2]}`} width={100} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-evenly",
    // alignItems: "center",
    textAlign: "center",
    // backgroundColor: "#AE8CD2", 
    color: "white",
    width: "100%",
    height: window.innerHeight,
    // border: "1px red solid",
  },
  aqua: {
    
  }
};

export default SectionThree
