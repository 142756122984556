import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from "gsap";

//
import { Box, Typography } from '@mui/material';

const LinkComponent = ({name, colors, url, width, ...props}) => {
  const el = useRef();
  // const navigate = useNavigate();

  const q = gsap.utils.selector(el);

  const onEnter = (e) => {
    gsap.fromTo(e, {opacity: 0, y:10}, {opacity:1, y:0, ease: "elastic"});
  };

  const onLeave = (e) => {
    gsap.fromTo(e, {opacity: 1, y:0}, {opacity:0, y:10});
  };

  return (
    <Box
      ref={el}
      alignContent={"center"}
      pb={0.8}
      width={width}
      sx={{cursor: "pointer"}}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      onMouseEnter={() => onEnter(q(".border"))}
      onMouseLeave={() => onLeave(q(".border"))}
      // onClick={() => navigate(url)}
      {...props}      
    >
        <a
          href={url}
          color="secondary"
          underline="none"
          style={{textDecoration: "none"}}
        >
          <Typography
            // align="center"
            variant="menu"
          >
            {name}
          </Typography>
        </a>
      <Box 
        className="border"
        sx={{borderBottom: `8px ${colors[1]} solid`, opacity:0, width: "100%"}}
      />
    </Box>
  );
};

LinkComponent.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  colors: PropTypes.array,
  width: PropTypes.number
};

LinkComponent.defaultProps = {
  color: "#FFFFFF",
  width: 100
};

//

export default LinkComponent;
