import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Home, Who } from './pages';
//
// import GlobalStyles from 'src/theme/style';
import theme from "src/theme";
import Layout from "src/theme/layout";
import { DispatchContext, StateContext } from 'src/contexts';
import { useGlobalState } from 'src/hooks';


function App() {
  const [state, dispatch] = useGlobalState();
  
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <ThemeProvider theme={theme}>
          {/* <GlobalStyles /> */}
          <Layout>
            <Router>
              <Routes>
                <Route path="/" element={ <Home /> } />
                <Route path="/who" element={ <Who /> } />
              </Routes>
            </Router>
          </Layout>
        </ThemeProvider>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

export default App;
