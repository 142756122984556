import React, { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Box, Stack, Typography } from "@mui/material";

const SectionThree = () => {
  const tl = useRef();
  const el = useRef();
  const q = gsap.utils.selector(el);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".section3",
        start: "top center-=25%"
      }
    })
    .fromTo(q(".koo"), {opacity:0, y:20}, {opacity:1, y:0, ease:"bounce"})
    .fromTo(q(".black-cat"), {opacity:0, y:20}, {opacity:1, y:0, ease:"bounce"})
    .fromTo(q(".recent"), {opacity:0, zoom:0.5}, {opacity:1, zoom: 0.95, ease: "bounce"});

  }, [q]);

  const onEnter = (e) => {
    gsap.fromTo(e, {opacity: 0, y:20}, {opacity:1, y:0});
  };

  const onLeave = (e) => {
    gsap.fromTo(e, {opacity: 1, y:0}, {opacity:0, y:20});
  };
  
  return (
    <Box
      ref={el}
      className="section3"
      style={styles.root}
    >
      <Box
        style={styles.items}
      >
        <Stack style={styles.productStack}>
          <Box
            className="koo"
            width={{md:150, xl:249}}
            onMouseEnter={() => onEnter(q(".koo-title"))}
            onMouseLeave={() => onLeave(q(".koo-title"))}
            sx={{cursor: "pointer"}}
          >
            <img
              alt="koo"
              src="/assets/images/koo.png"
              width={"100%"} 
            />
          </Box>
          <Box
            className="koo-title"
            style={styles.title}
          >
            <Typography fontSize={14} fontWeight="bold">
              LABEL DESIGN
            </Typography>
            <Typography fontSize={12}>
              Lorem Ipsum Dolem Rit Honor
            </Typography>
          </Box>
        </Stack>

        <Box 
          className="recent"
          display="flex"
          justifyContent={"space-evenly"}
          height={300}
          width={"100%"}
        >
          <Box pt={5}>
            <img
              alt="left-arrow"
              src="/assets/images/long-arrow-left.svg"
              width={80}
            />
          </Box>
          <Box>
            <Typography
              mt={{md:3, xl:0}}
              fontSize={{md:32, xl:64}}
              fontFamily={"Avenir-Black"}
            >
              RECENT
            </Typography>
            <Typography
              fontSize={{md:12, xl:24}}
            >
              VIEW RECENT WORK
            </Typography>
          </Box>
          <Box pt={5}>
            <img
              alt="left-arrow"
              src="/assets/images/long-arrow-right.svg"
              width={80}
            />
          </Box>
        </Box>
        
        <Stack style={styles.productStack}>
          <Box
            className="black-cat"
            width={{md: 300, xl:475}}
            onMouseEnter={() => onEnter(q(".bct-title"))}
            onMouseLeave={() => onLeave(q(".bct-title"))}
            sx={{cursor: "pointer"}}
          >
            <img
              alt="Black Cat"
              src="/assets/images/black-cat.png"
              width={"100%"} 
            />
          </Box>
          <Box
            className="bct-title"
            style={styles.title}
          >
            <Typography fontSize={14} fontWeight="bold">
              MASCOT DESIGN
            </Typography>
            <Typography fontSize={12}>
              All Gold Jams &amp; Black Cat Peanut Butter
            </Typography>
          </Box>
        </Stack>
      </Box>
      
      <Box style={styles.bg} />

    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#007AB7", 
    color: "white",
    height: window.innerHeight,
    // border: "1px red solid",
  },
  bg: {
    position: "static", 
    bottom: 0, 
    backgroundColor: "#E0B4C5",
    width: "100%",
    height: window.innerHeight * 0.4,
    overflowX: "hidden",
    // overflowY: ""
  },
  items: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative", 
    bottom: "-32%",
    width: "75%",
    // border:"1px solid purple",
    overflow: "hidden"
  },
  productStack: {
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center", 
    // border:"1px solid orange"
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: 250,
    height: 100,
    padding: 20,
    backgroundColor: "white",
    color: "#007AB7",
    opacity: 0,
    border: "8px black dashed",
  }
};

export default SectionThree
