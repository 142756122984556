import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  // useTheme 
} from "@mui/material";

import { Logo, Link } from 'src/components';
import { StateContext } from 'src/contexts';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles(theme => ({
//   root: {
//     // display: "flex",
//     // backgroundColor: theme.palette.primary.main
//   },
//   toolbar: {
//     height: 100, //64
//   }
// }));

const TopBar = ({ className, ...rest }) => {
  // const theme = useTheme();
  const state = useContext(StateContext);
  
  // const classes = useStyles();

  const links = [
    { name: "INTRO", url: "/", colors: ["#FFFFFF", "#AE8CD2"] },
    { name: "WHO", url: "/who", colors: ["#FFFFFF", "#E0E0A0"] },
    { name: "WHAT", url: "#", colors: ["#FFFFFF", "#E0B4C5"] },
    { name: "WORK", url: "#", colors: ["#FFFFFF", "#80E1FF"] },
  ];
    
  return (
    <Box
      height={110}
      pl={3}
      pr={3}
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      // sx={{backgroundColor: theme.palette.primary.main}}
      sx={{backgroundColor: state.backgroundColor}}
    >
      <Box>
        <Logo width={85} />
      </Box>

      <Box>
        <ul style={styles.list}>
          {links.map((link, i) => (
            <li key={i} style={styles.listItems}>
              <Link name={link.name} url={link.url} colors={link.colors} />
              {/* <a href={link.url} style={styles.links}>{link.name}</a> */}
            </li>
          ))}
        </ul>
      </Box>
      
      <Box>
        <Link name="CONTACT" url={"#"} colors={["white", "white"]} />
      </Box>
    </Box>
  );
};

const styles = {
  list: {
    display: "flex", 
    listStyle: "none", 
    justifyContent: "space-between", 
    // fontWeight: "bold"
  },
  listItems: {
    marginRight: 20
  },
  links: {
    color: "white",
    textDecoration: "none"
  }
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
