import React, { useContext, useEffect } from 'react';
import { 
  Box, 
  // useTheme 
} from '@mui/material';

import FigureHead from "./FigureHead";
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import { DispatchContext } from 'src/contexts';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const theme = useTheme();
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    dispatch({type: "set-menu-color", payload: "#007AB7"})
  }, [dispatch]);

  return (
    <Box>
      <FigureHead />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
    </Box>
  );
}
