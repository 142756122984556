import React from 'react'
// import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  // Grid,
  // Container,
  // Hidden,
  Typography,
  // Link
} from '@mui/material';

// import { makeStyles } from '@mui/styles'; 

// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';

// import { Logo } from 'src/components';

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: "flex",
//     height: 400,
//     width: "100%",
//     backgroundColor: theme.palette.primary.main
//   },
//   content: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     paddingTop: "4%",
//     // border: "1px white solid"
//   },
//   items: {
//     display: "flex",
//     justifyContent: "space-evenly",
//     flexWrap: "wrap",
//     // textAlign: "center",
//     // border: "1px #ccc solid",
//     "& > li": {
//       marginRight: 30,
//       marginBottom: 10,
//       listStyle: "none",
//       fontWeight: "bold"
//       // width: 180,
//     },
//     "& > li:last-child": {
//       marginRight: 0,
//     },
//     "& > li a": {
//       color: theme.palette.secondary.main,
//     }
//     // "& > li:last-child": {
//     //   borderRight: "none",
//     // },
//   },
//   social: {
//     marginTop: 20,
//     display: "flex",
//     justifyContent: "center",
//     color: "white",
//     "& > li": {
//       marginRight: 20,
//       marginBottom: 10,
//       listStyle: "none"
//     },
//     "& > li:last-child": {
//       marginRight: 0,
//     }
//   },
//   center: {
//     display: "flex",
//     alignItems: "center",
//     flexDirection: "column"
//   }
// }));


// const socialList = [
//   {title: "Facebook", icon: <FacebookIcon />, href: "#"},
//   {title: "Instagram", icon: <InstagramIcon />, href: "#"},
//   {title: "Twitter", icon: <TwitterIcon />, href: "#"}
// ];

const Footer = () => {
  // const classes = useStyles();

  // const social = socialList.map((item, i) => {
  //   return (
  //     <li key={i}>
  //       {item.icon}
  //     </li>
  //   );
  // });

  return (
    <footer style={styles.root}>
      
      <Box sx={{background: "pink"}}>
        <Typography variant="body1">
          The Curve Revolution &copy; 2022 Creative Fixer - All Rights Reserved.
        </Typography>
      </Box>

      <Box sx={{background: "aqua"}}>
        <Typography variant="body1">
          Social Media Icons
        </Typography>
      </Box>
    </footer>
  );
};

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    height: 80
  }
};

export default Footer;