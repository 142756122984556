import React, { useRef } from "react";
import gsap from "gsap";
import { Box } from "@mui/material";

const SectionThree = () => {
  const tl = useRef();
  const el = useRef();
  const q = gsap.utils.selector(el);

  return (
    <Box
      style={styles.root}
    >
      <Box
        style={styles.blue}
      >
        <Box
          mt={8}
          ml={6}
          width={{xl:380}}
          style={styles.logo}
        >
          <img
            alt="CFX Logo Title"
            src="/assets/images/cfx-title-logo.svg"
            width={"100%"}
          />
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "center",
    background: "#E0B4C5 url('/assets/images/figure-head.png') no-repeat center 120px",
    // backgroundSize: "28%",
    color: "white",
    height: window.innerHeight - 90,
    // border: "1px red solid",
  },
  blue: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "#007AB7",
    backgroundColor: "#003956",
    width: "100%",
    height: "63%",
    opacity: 0.9,
  },
  logo: {
    opacity: 1,
    // border: "1px red solid"
  }
};

export default SectionThree
