import React, { useLayoutEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';


const SectionOne = () => {
  const tl = useRef();
  const el = useRef();
  
  const q = gsap.utils.selector(el);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".head",
        start: "top center"
      }
    })
    .fromTo(q(".head"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt1"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt2"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt3"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".logo"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.5, ease:"bounce"})
    .fromTo(q(".txt4"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25}, "<-=0.5");

    const hideTopAnimation = gsap.to(q(".top"), {
      scrollTrigger: {
        trigger: ".top",
        start: "bottom 70%",
        toggleActions: "play pause reverse reset"
      },
      y: 50,
      opacity: 0
    });

    const expandAnimation = gsap.to(q(".logo"), {
      scrollTrigger: {
        trigger: ".bottom",
        start: "top 90%",
        toggleActions: "play pause reverse reverse",
        scrub: true
      },
      width:120,
      y: -20
    });

    return () => {
      // tl.kill();
      hideTopAnimation.kill();
      expandAnimation.kill();
    };
  }, [q]);

  return (
    <Box
      ref={el}
      className="section"
      style={styles.root}
    >
      <Box className="top">
        <Typography
          className="head"
          variant="h3"
          fontSize={{xs: 18}}
          mb={3}
        >
          CREATIVE FIXER STUDIO
        </Typography>

        <Box
          mb={6}
          padding={{sm: "0 2px", md:"0 180px", xl: "0 500px"}}
        >
          <Typography
            className="txt1"
            fontSize={{xs:34, md: 48}}
            fontFamily={"Avenir-Black"}
          >
            A marketing &amp; advertising solutions agency on the rise.
          </Typography>
        </Box>

        <Box
          mb={10}
          padding={{sm: "0 2px", md:"0 250px", xl: "0 580px"}}
        >
          <Typography 
            className="txt2"
            fontSize={21}
          >
            We offer through the line solutions that are integrated and serve 
            both businesses and their intended target audiences.
          </Typography>
          <br /><br />
          <Typography 
            className="txt3"
            fontSize={21}
          >
            100% Black-owned media company.
          </Typography>

          <Typography 
            className="txt3"
            fontSize={21}
          >
            Owned by
          </Typography>
        </Box>
      </Box>

      <Box className="bottom">
        <img 
          className="logo"
          alt="The Curve Revolution" 
          src="/assets/images/curve-revolution.svg"
          width={50}
        />

        <Typography
          className="txt4"
          mt={1} 
          fontSize={18}
          fontWeight={"bold"}
        >
          THE CURVE REVOLUTION
        </Typography>
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#007AB7", 
    color: "white",
    height: window.innerHeight,
    // border: "1px red solid",
  }
};

export default SectionOne
