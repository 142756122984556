import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Box, Button, Stack } from "@mui/material";

const FigureHead = () => {
  const tl = useRef();
  const el = useRef();
  
  const q = gsap.utils.selector(el); 

  
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    
    const posX = (window.innerWidth + 32) / -1;
    const posY = window.innerWidth - 32; // 32 = width of right target (cross hair)

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".figure",
        start: "top center",
        toggleActions: "play pause resume reset"
      }
    })
    .fromTo(q(".figure"), {opacity: 0, y: "-=30"}, {y: 0, opacity: 1, ease: "power1.inOut"}, "figure")
    .fromTo(q(".title"), {opacity: 0, y:200 }, {y:0, opacity: 1},"title", "figure<-=0.22")
    .fromTo(q(".target-left"), {x:posX}, {x:0}, "title<-=.5")
    .fromTo(q(".target-right"), {x:`+=${posY}`}, {x:0}, "title<-=.5")
    .fromTo(q(".arrow-down"), {y:0}, {y:10, repeat: 3, delay: 1, repeatDelay: 0.9, duration: 0.1});

    return () => {
      // tl.kill();
    };
  }, [q]);

  return (
    <Stack
      ref={el}
      alignItems={"center"}
      width={"100%"} 
      height={638}
      style={styles.container} 
    >
      <Box className="figure" style={styles.figure}>
        <img 
          alt="figure-head"
          src="/assets/images/figure-head-blue.png" 
          width={400} 
        />
      </Box>
        
      <Box 
        width={{ sm: 300, md: 850 }}
        style={styles.title}
      >
        <Box mt={-3}>
          <img 
            className="target-left"
            alt="target-left"
            src="/assets/images/target-left.svg" 
            width={32}
          />
        </Box>
        <Box
          width={{sm:100, md:320}}
        >
          <img 
            className="title"
            alt="cfx-title"
            src="/assets/images/cfx-title-shadow.svg" 
            width={"100%"} 
          />
        </Box>
        <Box mt={-3}>
          <img 
            className="target-right"
            alt="target-right"
            src="/assets/images/target-right.svg" 
            width={32} 
          />
        </Box>          
      </Box>
      

      <Box 
        pt={8}
        pb={4}
        mt={-4}
      >
        <Button
          className="arrow-down"
        >
          <img alt="Down Arrow" src="/assets/images/arrow-down.svg" width={40} />
        </Button>
      </Box>
    </Stack>
  );
};

const styles = {
  container: {
    // display: "flex",
    // justifyContent: "center",
    // width: "100%",
    // height: window.innerHeight,
    backgroundColor: "#007AB7",
    // border: "1px solid red",
  },
  figure: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // width: window.innerWidth - 18,
    // height: 300,
    // padding: 10,
    // fontSize: 16
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    top: -270,
    // left: 15,
    height: 0,
    // border: "1px red solid"
  }
};


export default FigureHead;
