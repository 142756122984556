import React from 'react';
// import { Outlet } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
import { Box } from "@mui/material";

import TopBar from './TopBar';
import Footer from './Footer';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     display: 'flex',
//     flexDirection: 'column',
//     // height: '100%',
//     width: '100%',
//     overflow: 'hidden'
//   },
//   wrapper: {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden',
//     // paddingTop: 64
//   },
//   container: {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden'
//   },
//   content: {
//     flex: '1 1 auto',
//     height: '100%',
//     overflow: 'auto'
//   }
// }));

const Layout = ({children}) => {
  // const classes = useStyles();
  // const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <div style={styles.root}>
      <TopBar />

      <div style={styles.wrapper}>
        <div style={styles.container}>
          <Box style={styles.content}>
            {children}
          </Box>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const styles = {
  root: {
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 64
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
};

export default Layout;
