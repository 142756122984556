import React, { useLayoutEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';


const SectionOne = () => {
  const tl = useRef();
  const el = useRef();
  
  const q = gsap.utils.selector(el);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".top",
        start: "center center"
      }
    })
    .fromTo(q(".head"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt1"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt2"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt3"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25})
    .fromTo(q(".txt4"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25}, "<-=0.5");

    const hideTopAnimation = gsap.to(q(".top"), {
      scrollTrigger: {
        trigger: ".top",
        start: "bottom 30%",
        toggleActions: "play pause reverse reset"
      },
      y: 50,
      opacity: 0
    });

    return () => {
      // tl.kill();
      hideTopAnimation.kill();
    };
  }, [q]);

  return (
    <Box
      ref={el}
      className="section"
      style={styles.root}
    >
      <Box className="top">
        <Typography
          className="head"
          variant="h5"
          mb={3}
        >
          AS CREATIVE FIXERS
        </Typography>

        <Box
          mb={6}
          padding={{sm: "0 2px", md:"0 180px", xl: "0 500px"}}
        >
          <Typography
            className="txt1"
            variant="h1"
            // fontSize={{xs:34, md: 48}}
            fontWeight={"bold"}
          >
            We partner with brands and industry peers.
          </Typography>
        </Box>

        <Box
          mb={10}
          padding={{sm: "0 2px", md:"0 250px", xl: "0 580px"}}
        >
          <Typography 
            className="txt2"
            fontSize={21}
          >
            We believe in building collaborative relationships, 
            finding custom solutions that bring value to your business.
          </Typography>
          <br /><br />
          <Typography 
            className="txt3"
            fontSize={21}
          >
            We create effective, integrated marketing and advertising 
            campaigns that resonate with your intended target audience.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#AE8CD2", 
    color: "white",
    height: window.innerHeight,
    // border: "1px red solid",
  }
};

export default SectionOne
