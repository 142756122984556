import React, { useLayoutEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';

const SectionTwo = () => {
  const tl = useRef();
  const el = useRef();
  
  const q = gsap.utils.selector(el);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-top",
        start: "30% center"
      }
    })
    .fromTo(q(".head"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.35})
    .fromTo(q(".title1"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.35})
    .fromTo(q(".title2"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.35})
    .fromTo(q(".title3"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.35})
    .fromTo(q(".items"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.5, ease:"bounce"});
    // .fromTo(q(".txt4"), {y:10, opacity:0}, {y:0, opacity:1, duration:0.25}, "<-=0.5");

    const hideTopAnimation = gsap.to(q(".section-top"), {
      scrollTrigger: {
        trigger: ".section-top",
        start: "top top",
        toggleActions: "play pause reverse none"
      },
      y: 50,
      opacity: 0
    });

    return () => {
      // tl.kill();
      hideTopAnimation.kill();
    };
  }, [q]);

  return (
    <Box
      ref={el}
      className="section"
      style={styles.root}
    >
      <Box 
        className="section-top"
        style={styles.top}
      >
        <Typography
          className="head"
          variant="h3"
          fontSize={{xs: 18, md:24}}
          mb={3}
        >
          SERVICES
        </Typography>

        <Box
          mb={6}
          width={"100%"}
          display="flex"
          justifyContent={"space-evenly"}
          flexWrap={"wrap"}
          // sx={{border: "1px pink solid"}}
          // padding={{sm: "0 2px", md:"0 180px", xl: "0 200px"}}
        >
          <Box style={styles.item}>
            <Typography
              className="title1"
              variant="h1"
            >
              Discovery
            </Typography>
            <ul 
              className="items"
              style={styles.list}
            >
              <li>Research</li>
              <li>Strategy</li>
              <li>Ideation</li>
            </ul>
          </Box>

          <Box style={styles.item}>
            <Typography
              className="title2"
              variant="h1"
            >
              Create
            </Typography>
            <ul 
              className="items"
              style={styles.list}
            >
              <li>Concepts</li>
              <li>Scripts</li>
              <li>Branding</li>
              <li>Visual</li>
              <li>Design</li>
              <li>Illustration</li>
              <li>Web Design</li>
              <li>Digital Design</li>
              <li>Social Media</li>
            </ul>
          </Box>

          <Box style={styles.item}>
            <Typography
              className="title3"
              variant="h1"
              fontSize={{xs:34, md: 48}}
            >
              Production
            </Typography>
            <ul 
              className="items"
              style={styles.list}
            >
              <li>OOH</li>
              <li>Point of Sale </li>
              <li>In-store</li>
              <li>Radio spots</li>
              <li>TV Commercials</li>
              <li>TV Programmes</li>
              <li>Print</li>
              <li>Photography</li>
              <li>Social Media Content</li>
              <li>Events</li>
            </ul>
          </Box>

        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    textAlign: "center",
    backgroundColor: "#AE8CD2", 
    color: "white",
    height: window.innerHeight,
    // border: "1px black solid",
  },
  top: {
    // border: "5px black solid"
  },
  item: {
    width: 256,
    // border: "1px black solid"
  },
  list: {
    fontSize: 18,
    listStyle: "none",
    padding: 0,
    // border: "1px solid white"
  }
};

export default SectionTwo
